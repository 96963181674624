@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.h1 {
		@apply text-black text-2xl md:text-3xl lg:text-5xl mb-4 lg:mb-6;
	}
	.h2 {
		@apply text-black text-xl lg:text-3xl mb-4 lg:mb-6;
	}
	.h3 {
		@apply text-black text-xl lg:text-2xl mb-4 lg:mb-6;
	}
	.h4 {
		@apply text-black text-base lg:text-xl mb-4;
	}
	.btn-primary {
		@apply bg-orange-500 rounded px-4 py-3 !text-white hover:brightness-110;
	}
}
:root {
  --swiper-navigation-size: 30px!important;
}

.swiper-button-next, .swiper-button-prev {
  color: white!important;
  width: calc(var(--swiper-navigation-size) / 21 * 42)!important;
}

.swiper-light .swiper-button-next {
  color: black!important;
}

.swiper-light .swiper-button-prev {
  color: black!important;
}

.eapps-instagram-feed-popup-close {
	top: 80px!important;
}
